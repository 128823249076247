<template>
  <v-app class="wmyHomeApp">
    <v-banner
      single-line
      class="banner"
    >
      <v-img src="https://h5.ophyer.cn/official_website/banner/wmy-h5-agent-banner.png" width="100%" contain>
        <template v-slot:placeholder><img-placeholder></img-placeholder></template>
        <v-card-title class="justify-center text-align-center pa-0 font-size-h5banner-title line-height-40 white-space-pre-wrap color-FFF">全国诚招合作伙伴 <br>诚邀您的加入</v-card-title>
      </v-img>
    </v-banner>


    <!-- 我们的优势 -->
    <div class="width-per-100 pb-3 px-4 mt-10">
      <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">我们的优势</v-card-title>
      <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">5G时代 + 政策扶持时代机遇，顺势而为</v-card-text>
      <v-tabs v-model="advantageTab" background-color="transparent" color="white" dark centered hide-slider
              class="xs-tab">
        <v-tab v-for="(v, k) in advantageList" :key="k" style="min-width: unset;padding: 0 5px;">
          <div class="tab-name-btn" :class="advantageTab === k ? 'tab-active':''">{{ v.title }}</div>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-1 transparent" v-model="advantageTab" style="border-radius: 10px;">
        <v-tab-item v-for="(v, k) in advantageList" :key="k" :transition="false">
          <v-card flat height="100%" class="pa-5 pb-0">
            <div v-for="(info,index) in v.children" :key="index" class="">
              <v-card-title class="pa-0 font-size-15 line-height-16 font-weight-bold">
                <div class="tip_label"></div>
                {{info.title}}
              </v-card-title>
              <v-card-text class="pa-0 pb-5 mt-2 line-height-17 color-666 font-size-12">{{info.tip}}</v-card-text>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <!--<v-card flat class="width-per-100" style="background-color: #f5f8ff;">-->
    <!--  <v-card-title class="justify-center font-weight-bold font-size-20 pa-0 mt-7 line-height-28">公司荣誉</v-card-title>-->
    <!--  <div class="px-4 mt-4 width-per-100 position-r">-->
    <!--    <div class="tab-btn-prev" @click.stop="handleTabBtnTwo('prev')"></div>-->
    <!--    <div class="tab-btn-next" @click.stop="handleTabBtnTwo('next')"></div>-->
    <!--    <div class="d-flex width-per-100 about-jo-dom" ref="aboutJo">-->
    <!--      <div class="about-jb-img1 bg-color-FFF">-->
    <!--        <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-1.jpg"></v-img>-->
    <!--      </div>-->
    <!--      <div class="ml-3">-->
    <!--        <div class="about-jb-img2 bg-color-FFF">-->
    <!--          <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-5.jpg"></v-img>-->
    <!--        </div>-->
    <!--        <div class="about-jb-img2 bg-color-FFF" style="margin-top: 10px">-->
    <!--          <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-6.jpg"></v-img>-->
    <!--        </div>-->

    <!--      </div>-->
    <!--      <div class="ml-3">-->
    <!--        <div class="about-jb-img2 bg-color-FFF">-->
    <!--          <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-7.jpg"></v-img>-->
    <!--        </div>-->
    <!--        <div class="about-jb-img2 bg-color-FFF" style="margin-top: 10px">-->
    <!--          <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-8.jpg"></v-img>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="about-jb-img1 bg-color-FFF ml-3">-->
    <!--        <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-2.jpg"></v-img>-->
    <!--      </div>-->
    <!--      <div class="about-jb-img1 bg-color-FFF ml-3">-->
    <!--        <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-3.jpg"></v-img>-->
    <!--      </div>-->
    <!--      <div class="ml-3">-->
    <!--        <div class="about-jb-img2 bg-color-FFF">-->
    <!--          <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-9.jpg"></v-img>-->
    <!--        </div>-->
    <!--        <div class="about-jb-img2 bg-color-FFF" style="margin-top: 10px">-->
    <!--          <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-10.jpg"></v-img>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="about-jb-img1 bg-color-FFF ml-3">-->
    <!--        <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-4.jpg"></v-img>-->
    <!--      </div>-->
    <!--      <div class="ml-3">-->
    <!--        <div class="about-jb-img2 bg-color-FFF">-->
    <!--          <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-11.jpg"></v-img>-->
    <!--        </div>-->
    <!--        <div class="about-jb-img2 bg-color-FFF" style="margin-top: 10px">-->
    <!--          <v-img width="100%" height="100%" contain src="https://h5.ophyer.cn/official_website/other/about-jb-12.jpg"></v-img>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</v-card>-->

    <v-card class="px-4 py-6" flat color="transparent" style="padding-top: 40px !important;">
      <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0">{{partner.title}}</v-card-title>
      <v-card-text class="d-flex justify-center font-size-12 color-666 pa-0 mt-2">{{partner.subtitle}}</v-card-text>
      <div class="mt-4">
        <v-row>
          <v-col cols="6" v-for="(row,rowIndex) in partner.itemList" :key="rowIndex" class="pa-2">
            <v-card flat style="border-radius: 10px;">
              <div class="d-flex justify-center">
                <v-img :src="row.src" contain width="100%" height="100"></v-img>
              </div>
              <v-card-title class="pa-0 font-size-15 line-height-22 justify-center text-align-center font-weight-bold color-333 mt-2">
                {{row.title}}
              </v-card-title>
              <v-card-text class="pl-3 pr-3 mt-1 ml-1 text-align-center" style="height: 70px;">
                <span class="font-size-12 color-666 font-weight-300 line-height-17">{{row.content}}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>

  </v-app>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      bannerList: ["https://h5.ophyer.cn/official_website/banner/h5Home-banner01.png","https://h5.ophyer.cn/official_website/banner/h5Home-banner02.png"],
      advantageTab: 0,
      tab: 0,
      advantageList: [
        {
          title: "市场前景广阔",
          children: [
            {
              title: "政策扶持",
              tip: "近年来国家不断推出政策、发展基金等助推产业发展；国家十四五规划将AR/VR技术正式写入数字经济重点产业领域"
            },
            {
              title: "5G+ 时代",
              tip: "5G时代来临，信息网络更加快速、稳定成熟，为AR/VR的发展提供了良好的契机"
            },
            {
              title: "产业聚集",
              tip: "随着VR技术的更迭，VR产业逐渐形成规模化、应用化，市场需求量骤增"
            }
          ]
        },
        {
          title: "稳定技术支持",
          children: [
            {
              title: "技术基础坚实",
              tip: "10年技术沉淀，深耕AR/VR、AI及营销领域，在国内的AR/VR智慧营销领域名列前茅"
            },
            {
              title: "全栈自研技术",
              tip: "独立自主研发AR/VR引擎 ｜ 累计获得软件著作权234个，授权专利4个，作品著作权21个"
            },
            {
              title: "稳定开发团队",
              tip: "100 + 技术团队强有力支撑，团队成员稳定，为系统开发升级保驾护航"
            }
          ]
        },
        {
          title: "全面服务保障",
          children: [
            {
              title: "智能数据分析",
              tip: "营销活动效果监控，实时追踪活动数据，为营销方案调整提供数据支持"
            },
            {
              title: "多渠道在线客服",
              tip: "7x24小时应急响应，只要您需要，我们随时在身边"
            },
            {
              title: "顾问式运营服务",
              tip: "项目全链路追踪，全程指导，为营销活动持续助力"
            }
          ]
        },
      ],
      partner: {
        title:'为什么选择我们',
        subtitle:'服务活动营销全链路，一体化赋能营销目标',
        itemList:[
          {
            title:"高额收益",
            src: 'https://h5.ophyer.cn/official_website/other/InvestmentPromotion-icon-1.png',
            content: "高额销售返利，新签客户、老客户续约、定制化服务",
          },
          {
            title:"全面培训",
            src: require('@/../public/img/icons/InvestmentPromotion/InvestmentPromotion-2.png'),
            content: "产品、市场、营销全面无死角培训，帮助合作伙伴最短时间内了解行业",
          },
          {
            title:"强力扶持",
            src: require('@/../public/img/icons/InvestmentPromotion/InvestmentPromotion-3.png'),
            content: "渠道经理驻地支持，帮助合作伙伴快速开拓市场",
          },
          {
            title:"销售工具",
            src: require('@/../public/img/icons/InvestmentPromotion/InvestmentPromotion-4.png'),
            content: "提供线上线下全面销售工具，帮助合作伙伴提高效率",
          },
          {
            title:"推广物料",
            src: require('@/../public/img/icons/InvestmentPromotion/InvestmentPromotion-5.png'),
            content: "提供合作伙伴市场推广时所用的营销物料",
          },
          {
            title:"服务支撑",
            src: require('@/../public/img/icons/InvestmentPromotion/InvestmentPromotion-6.png'),
            content: "专属客户服务，帮助合作伙伴解决产品、技术等问题",
          }
        ]
      },
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    handleTabBtn: function (type){
      const maxTab = this.industrySolutions.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    },
    handleTabBtnTwo: function (type){
      //获取当前可视区宽度
      let centerWidth = document.body.clientWidth/2 - 6;
      let scrollLeft = this.$refs.aboutJo.scrollLeft;
      if(type === "prev"){
        scrollLeft -= centerWidth;
      }else if(type === "next"){
        scrollLeft += centerWidth;
      }
      this.$refs.aboutJo.scrollTo({
        left: scrollLeft,
        behavior: "smooth"
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  ::v-deep .v-responsive__content {
    display: flex;
    justify-content: center;
  }

}

.about-jo-dom{
  width: calc(100vw - 32px);
  // box-shadow: 0px 0px 18px 0px rgba(0, 92, 193, 0.16);
  overflow-x: auto;
  .about-jb-img1{
    width: calc(50vw - 22px);
    height: 258px;
    // box-shadow: 0px 0px 12px 0px rgba(0, 92, 193, 0.16);
    background: #fff !important;
    // border-radius: 5px;
  }
  .about-jb-img2{
    width: calc(50vw - 22px);
    height: 124px;
    // box-shadow: 0px 0px 12px 0px rgba(0, 92, 193, 0.16);
    // border-radius: 5px;
  }
}
.wmyHomeApp{
  background: #f5f8ff;
}

</style>
